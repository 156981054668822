import React, { useEffect } from 'react';
import portManager from '../../../printer/PrinterBluetooth';
import usbManager from '../../../printer/PrinterUsb';
import { triggerToast } from '../../../utils';
import { AuthenticatedFetch } from '../../../lib';
import { printDocumentQueue } from './utils';
import useSound from 'use-sound';
import boopSfx from '../../../assets/Ding-Ding-Sound-Effect.mp3';

export default function PrinterPage() {
  const [printerLocationId, setPrinterLocationId] = React.useState(null);
  const [printerLocations, setPrinterLocations] = React.useState([]);
  const [usbData, setUsbData] = React.useState({ name: null, configuration: null });
  const [bluetoothData, setBluetoothData] = React.useState({ id: null });
  const [autoPrintEnabled, setAutoPrintEnabled] = React.useState(false);
  const [printerType, setPrinterType] = React.useState(null);
  const [playAudio] = useSound(boopSfx);
  const [isPrinting, setIsPrinting] = React.useState(false);
  const [showPrinter, setShowPrinter] = React.useState(false)

  useEffect(() => {
    async function fetchData() {
      const [printerLocationsResponse] = await Promise.all([
        AuthenticatedFetch(`printer-location`),
      ]);

      const [printerLocationsData] = await Promise.all([
        printerLocationsResponse.json(),
      ]);

      setPrinterLocations(printerLocationsData);
    }

    fetchData();

  }, []);

  useEffect(() => {
    try {
      const interval = setInterval(async () => {
        if (isPrinting) return;
        try {
          setIsPrinting(true);
          if (printerLocationId === null) return;
          if (printerType === null || printerType === '') return;

          await printDocumentQueue(printerType, printerLocationId, portManager, usbManager, playAudio);
        } finally {
          setIsPrinting(false);
        }
      }, 1000 * (process.env.REACT_APP_PRINTER_INTERVAL_DURATION_IN_SECOND || 5));

      return () => clearInterval(interval);
    } catch (e) {
      console.log(e);
      alert(e);
    }
  }, [isPrinting, printerLocationId, printerType])

  const handleLocationChange = (event) => {
    const selectedLocationId = event.target.value;
    setPrinterLocationId(selectedLocationId);
    setAutoPrintEnabled(false);
  };

  const baseUrl = window.location.origin;

  return <div>
    <iframe style={{ width: "100vw", height: "100vh" }} src={baseUrl} ></iframe>

    <div className='flex min-h-screen min-w-full max-w-full justify-center items-center flex-col px-2 py-4'>
      Printer Document Queue Consumer
      <div className='flex-row justify-center'>
        <div className='mmd:text-[20px]'>Printing in for : {printerType}</div>
        <div className='mmd:text-[20px]'>Printer Location ID: {printerLocationId}</div>
        <div className='mmd:text-[20px]'>Status : {autoPrintEnabled ? 'printing' : ''}</div>
        <button className='btn' type='submit'
          onClick={async () => {
            let tempPrinterType = '';
            if (usbData?.name) {
              tempPrinterType = 'USB';
            } else if (bluetoothData?.id) {
              tempPrinterType = 'Bluetooth';
            }
            setPrinterType(tempPrinterType);
            printDocumentQueue(tempPrinterType, printerLocationId, portManager, usbManager);
          }}
        >
          Print Once
        </button>
      </div>
      <br />
      <br />
      <div>
        <label htmlFor='printerLocation'>Select Printer Location:</label>
        <select
          id='printerLocation'
          className='dropdown input input-bordered w-full font-semibold'
          value={printerLocationId || ''}
          onChange={handleLocationChange}
        >
          <option value=''>Select Location</option>
          {printerLocations.map((location) => (
            <option key={location.id} value={location.id}>
              {location.id} - {location.name}
            </option>
          ))}
        </select>
      </div>
      <br />
      <br />

      <div>
        <div>
          <div>Bluetooth Id: {bluetoothData.id}</div>
          <button className='btn' type='submit'
            onClick={async () => {
              setAutoPrintEnabled(false);
              await portManager.openPortConnection();
              const portInfo = portManager.getPortInfo();
              if (portInfo?.id !== undefined) {
                setBluetoothData(portInfo);
                printerType("USB");
              }
            }}
          >
            Connect
          </button>
          <button className='btn' type='submit'
            onClick={async () => {
              if (bluetoothData?.id === null || printerLocationId === null) return triggerToast('error', 'Harap conenct ke Bluetooth device dan pilih Printer Location terlebih dahulu');
              setBluetoothData(portManager.getPortInfo());
              setPrinterType('Bluetooth');
              setAutoPrintEnabled(true);
            }}
          >
            Print by Bluetooth
          </button>

          <button className='btn' type='submit'
            onClick={() => {
              portManager.closePortConnection();
              setBluetoothData({ id: null });
              setAutoPrintEnabled(false);
            }}
          >
            Disconnect
          </button>
        </div>

        <br />

        <div>
          <div>USB Name: {usbData.name}</div>
          <button className='btn' type='submit'
            onClick={async () => {
              setAutoPrintEnabled(false);
              await usbManager.openUsbConnection();
              setUsbData(usbManager.getUsbInfo());
              setPrinterType("USB")
            }}
          >
            Connect
          </button>
          <button className='btn' type='submit'
            onClick={async () => {
              if (usbData?.name === null || printerLocationId === null) return triggerToast('error', 'Harap conenct ke USB device dan pilih Printer Location terlebih dahulu');
              setUsbData(usbManager.getUsbInfo());
              setPrinterType('USB');
              setAutoPrintEnabled(true);
            }}
          >
            Print by USB
          </button>
          <button className='btn' type='submit'
            onClick={() => {
              usbManager.closeUsbConnection();
              setUsbData({ name: null, configuration: null });
              setAutoPrintEnabled(false);
            }}
          >
            Disconnect
          </button>
        </div>
      </div>
    </div>
  </div>
}

import React from 'react';
import { CashierContext } from '../../../context/CashierProvider';

export default function SearchBar() {
  const { nameToBeSearch, setNameToBeSearch } = React.useContext(CashierContext);

  return (
    <div className='flex gap-2'>
      <button className={"btn"} onClick={() => { setNameToBeSearch(undefined) }}>clear</button>
      <label className="input input-bordered flex items-center gap-2">
        <input
          type="text"
          className="grow"
          placeholder="Search by name"
          value={nameToBeSearch == undefined ? "" : nameToBeSearch}
          onChange={(e) => {
            setNameToBeSearch(e.target.value === '' ? undefined : e.target.value);
          }}
        />
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 16 16"
          fill="currentColor"
          className="w-4 h-4 opacity-70">
          <path
            fillRule="evenodd"
            d="M9.965 11.026a5 5 0 1 1 1.06-1.06l2.755 2.754a.75.75 0 1 1-1.06 1.06l-2.755-2.754ZM10.5 7a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Z"
            clipRule="evenodd"
          />
        </svg>
      </label>
    </div>
  );
}

import React from 'react';
import PropTypes from 'prop-types';
import { CashierOrderContext } from '../../../../context/CashierOrderProvider';

export default function MenuList({ menus }) {
  const { setMenuId, setAddonsProps, setQuantityProps, setOrderItemToBeUpdated } =
    React.useContext(CashierOrderContext);

  return (
    <div className="grid grid-cols-4 gap-4 p-4 items-center justify-center flex-wrap w-full">
      {menus.map((menu) => (
        <button
          key={menu.id}
          style={{height:'4rem'}}
          className="btn btn-outline border border-gray-400 hover:bg-gray-200/60 hover:border-gray-200 hover:text-black rounded-xl p-4 hover font-medium cursor-pointer"
          onClick={async () => {
            setMenuId(menu.id);
            setAddonsProps([]);
            setQuantityProps(1);
            setOrderItemToBeUpdated(undefined);

            document.querySelector('#modal-order').showModal();
          }}>
          <p>{menu.name}</p>
        </button>
      ))}
    </div>
  );
}

MenuList.propTypes = {
  menus: PropTypes.array.isRequired
};

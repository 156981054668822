import React, { useEffect } from 'react';
import { CashierOrderContext } from '../../../../context/CashierOrderProvider';
import { useForm } from 'react-hook-form';
import { TypeTab, scopesEnum } from '../../../../lib';
import { AuthContext } from '../../../../context/AuthProvider';
import { triggerToast } from '../../../../utils';

export default function OrderInformationBar() {
  const { order, handleUpdate, setOrderItems, tables } = React.useContext(CashierOrderContext);

  const { decideIfRoleHasAccess } = React.useContext(AuthContext);

  const {
    register,
    watch,
    reset,
    setError,
    clearErrors,
    formState: { dirtyFields, errors }
  } = useForm({
    defaultValues: {
      customerName: order.customerName,
      type: order.type,
      numberOfPeople: order.numberOfPeople,
      tableNumber: order.tableNumber
    }
  });

  useEffect(() => {
    reset({
      customerName: order.customerName,
      type: order.type,
      numberOfPeople: order.numberOfPeople,
      tableNumber: order.tableNumber
    });
  }, [order, reset]);

  function validateRole() {
    if (order.state == 'UNPLACED' && !decideIfRoleHasAccess(scopesEnum.UPDATE_UNPLACED_ORDER)) {
      triggerToast('error', 'You are not allowed to update order!');

      return false;
    }

    if (order.state == 'PLACED' && !decideIfRoleHasAccess(scopesEnum.UPDATE_PLACED_ORDER)) {
      triggerToast('error', 'You are not allowed to update order!');

      return false;
    }

    return true;
  }

  return (
    <div className="w-full">
      <div className="flex flex-col gap-1">
        <div className="text-sm font-semibold">Order Information</div>
        <div className="h-1 bg-base-200 w-32"></div>
      </div>
      <div
        className={`grid gap-1 ${
          order.type == 'DINE_IN' ? 'grid-cols-1 sm:grid-cols-2 md:grid-cols-4 ' : 'grid-cols-2'
        }`}>
        <label className="form-control w-full">
          <span className="label text-sm">Customer Name</span>
          <input
            className="input input-bordered w-full font-semibold"
            placeholder="Customer Name"
            {...register('customerName')}
            onBlur={async () => {
              clearErrors('customerName');
              if (dirtyFields.customerName) {
                if (watch('customerName') == '') {
                  setError('customerName', {
                    type: 'manual',
                    message: 'Customer Name is required!'
                  });
                  return;
                }
                handleUpdate('customerName', watch('customerName'), 'Customer Name', () => {
                  return validateRole();
                });
              }
            }}
          />
          <div className="label">
            <span className="label-text-alt text-red-500 font-semibold">
              {' '}
              {errors.customerName && errors.customerName.message}
            </span>
          </div>
        </label>
        <label className="form-control w-full">
          <span className="label text-sm">Order Type</span>
          <select
            className={`dropdown input input-bordered w-full font-semibold ${
              watch('type') ? '' : 'text-gray-400'
            }`}
            {...register('type')}
            onBlur={async () => {
              if (dirtyFields.type) {
                const type = watch('type');

                if (
                  (type == 'ONLINE' && order.type != 'ONLINE') ||
                  (type != 'ONLINE' && order.type == 'ONLINE')
                ) {
                  setOrderItems([]);
                }

                await handleUpdate('type', type, 'Order Type', () => {
                  return validateRole();
                });
              }
            }}>
            {TypeTab.map((orderType) => {
              if (orderType.value == 'ALL') return;
              return (
                <option key={`order-type-${orderType.label}`} value={orderType.value}>
                  {orderType.label}
                </option>
              );
            })}
          </select>
          <div className="label">
            <span className="label-text-alt text-red-500 font-semibold">
              {' '}
              {errors.type && errors.type.message}
            </span>
          </div>
        </label>
        {order.type == 'DINE_IN' && (
          <>
            <label className="form-control w-full">
              <span className="label text-sm">Table Number</span>
              <select
                className={`dropdown input input-bordered w-full font-semibold ${
                  watch('tableNumber') ? '' : ''
                }`}
                {...register('tableNumber')}
                onBlur={async () => {
                  if (dirtyFields.tableNumber) {
                    await handleUpdate(
                      'tableNumber',
                      Number(watch('tableNumber')),
                      'Table Number',
                      () => {
                        return validateRole();
                      }
                    );
                  }
                }}>
                <option value="" hidden>
                  Select Table
                </option>
                {tables.map((table) => {
                  return (
                    <option key={`table-${table.tableNumber}`} value={table.tableNumber}>
                      Table {table.tableNumber}
                    </option>
                  );
                })}
              </select>
              <div className="label">
                <span className="label-text-alt text-red-500 font-semibold">
                  {' '}
                  {errors.tableNumber && errors.tableNumber.message}
                </span>
              </div>
            </label>
            <label>
              <span className="label text-sm">Number of People</span>
              <input
                className="input input-bordered w-full font-semibold"
                placeholder="Number Of People"
                {...register('numberOfPeople', {
                  valueAsNumber: true,
                  minLength: 1
                })}
                onBlur={async () => {
                  clearErrors('numberOfPeople');
                  if (dirtyFields.numberOfPeople) {
                    if (
                      watch('numberOfPeople') == 0 ||
                      parseInt(watch('numberOfPeople')) < 0 ||
                      isNaN(parseInt(watch('numberOfPeople')))
                    ) {
                      setError('numberOfPeople', {
                        type: 'manual',
                        message: 'Invalid number of people'
                      });
                      return;
                    }
                    await handleUpdate(
                      'numberOfPeople',
                      watch('numberOfPeople'),
                      'Number of People',
                      () => {
                        return validateRole();
                      }
                    );
                  }
                }}
              />
              <div className="label">
                <span className="label-text-alt text-red-500 font-semibold">
                  {' '}
                  {errors.numberOfPeople && errors.numberOfPeople.message}
                </span>
              </div>
            </label>
          </>
        )}
      </div>
    </div>
  );
}

import React from 'react';
import { Link } from 'react-router-dom';
import { CashierProvider } from '../../../context/CashierProvider';
import ConfirmationModal from './ConfirmationModal';
import DateGroup from './Dategroup';
import InfoBar from './InfoBar';
import ModalBox from './ModalBox';
import PlacedOrder from './PlacedOrder';
import SearchBar from './SearchBar';
import StatusBar from './StatusBar';
import Searchbutton from './SearchButton';
import TypeBar from './TypeBar';
import PrinterBar from '../printer/PrinterBar';

export default function CashierPage() {
  return (
    <CashierProvider>
      <div className="flex min-h-screen min-w-full max-w-full justify-center items-center flex-col px-2 py-4">
        <div className="card md:w-[98%] bg-base-100 shadow-xl">
          <div className="text-sm breadcrumbs font-semibold px-8">
            <ul>
              <li>
                <Link to={'/cashier'}>Cashier</Link>
              </li>
            </ul>
          </div>
          {/* <PrinterBar /> */}
          <div style={{maxHeight:"80vh"}} className="card-body md:gap-8 sm:gap-6 gap-4 justify-between flex lg:flex-row flex-col w-full items-stretch">
            <div className="flex flex-1 flex-col md:gap-4 gap-2">
              <div className="w-full flex flex-row items-center justify-between">
                <StatusBar />
                <div className="flex flex-row items-center">
                {/* <Searchbutton /> */}
                <SearchBar />
                </div>
              </div>
              <div className="w-full flex flex-row items-center justify-between">
                <TypeBar />
              </div>
              <div className="w-full flex flex-row items-center justify-between gap-3">
                <button
                  className="btn text-[1rem]"
                  onClick={() => {
                    document.querySelector('#modal-cashier').showModal();
                  }}>
                  Buat Pesanan
                </button>
                <DateGroup />
              </div>
              <PlacedOrder />
            </div>
            <InfoBar />
          </div>
        </div>
      </div>
        <ModalBox />
        <ConfirmationModal />
    </CashierProvider>
  );
}

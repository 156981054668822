import React from 'react';
import { DashboardContext } from '../../../context/DashboardProvider';
import InfoBox from './InfoBox';

export default function SelectedInfoBox() {
  const { orders } = React.useContext(DashboardContext);

  const { total, tax } = React.useMemo(() => {
    if (orders.length === 0)
      return {
        total: 0,
        tax: 0
      };

    const total = Math.round(
      parseFloat(
        orders.reduce((result, order) => {
          return order.orderItems.reduce((result, orderItem) => {
            return result + orderItem.price;
          }, result);
        }, 0) / 1.1
      )
    );

    const tax = Math.round(parseFloat(total * 0.1));

    return {
      total: total,
      tax: tax
    };
  }, [orders]);

  return (
    <>
      <InfoBox title={'Pemasukan'} value={total} isCurrency={true} />
      <InfoBox title={'PPN'} value={tax} isCurrency={true} />
      <InfoBox title={'Jumlah Pesanan'} value={orders.length} />
    </>
  );
}

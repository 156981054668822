import React from 'react';
import { StatusTab } from '../../../lib';
import { CashierContext } from '../../../context/CashierProvider';

export default function StatusBar() {
  const { activeStatusTab, setActiveStatusTab } = React.useContext(CashierContext);

  return (
    <div role="tablist" className="tabs tabs-boxed w-fit md:tabs-md tabs-xs">
      {StatusTab.map((tab) => (
        <a
          role="tab"
          className={`font-bold text-xs sm:text-sm md:text-base tab ${
            activeStatusTab == tab.value ? 'tab-active font-extrabold' : ''
          }`}
          onClick={() => setActiveStatusTab(tab.value)}
          key={tab.label}>
          {tab.label}
        </a>
      ))}
    </div>
  );
}

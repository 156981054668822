import React from 'react';
import { useForm } from 'react-hook-form';
import { triggerToast } from '../utils';
import { AuthContext } from '../context/AuthProvider';
import { SetCookieToken } from '../lib';
import { useNavigate } from 'react-router-dom';

const LoginPage = () => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues: {
      username: '',
      password: ''
    }
  });

  const { setUserId } = React.useContext(AuthContext);
  const navigate = useNavigate();

  async function onSubmit(data) {
    const { username, password } = data;

    const res = await fetch(`${process.env.REACT_APP_API_URL}/users/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        username,
        password
      })
    });

    if (res.status < 400) {
      const result = await res.json();
      SetCookieToken(result.token);
      setUserId(result.user?.id);
      navigate('/');
      triggerToast('success', `Login Success!`);
    } else {
      const errMessage = await res.text();
      triggerToast('error', errMessage);
    }
  }

  return (
    <div
      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <div>
        <h2>Login Page</h2>
        <img src="/siantar.png" alt="logo" className="my-5" />
        <form onSubmit={handleSubmit(onSubmit)}>
          <label className="form-control w-full">
            <div className="label w-fit">
              <span className="label-text font-bold">Username</span>
            </div>
            <input
              type="text"
              placeholder="Enter Username"
              className="input input-bordered w-full font-semibold"
              {...register('username', {
                required: true
              })}
            />
          </label>
          <div className="label">
            <span className="label-text-alt text-red-500 font-semibold">
              {' '}
              {errors.username && 'Username is required!'}
            </span>
          </div>
          <>
            <label className="form-control w-full">
              <div className="label w-fit">
                <span className="label-text font-bold">Password</span>
              </div>
              <input
                type="password"
                placeholder="Enter Password"
                className="input input-bordered w-full font-semibold"
                {...register('password', {
                  required: true
                })}
              />
            </label>
            <div className="label">
              <span className="label-text-alt text-red-500 font-semibold">
                {' '}
                {errors.password && 'Password is required!'}
              </span>
            </div>
          </>

          <div className="flex flex-row items-center justify-center w-full gap-2">
            <button
              className="btn float-right text-black font-semibold w-full max-w-20"
              type="submit">
              Login
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoginPage;

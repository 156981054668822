export const formattedDoubleToRupiahString = (number) => {
  return number.toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
}

export const columnVariableValue = ({ variable, value, collumnFormat }) => {
  const variableString = String(variable);
  const maxVariableLength = Math.min(variableString.length, collumnFormat.variableColLength);
  const truncatedVariable = variableString.slice(0, maxVariableLength);

  const valueString = String(value);
  const remainingSpace = Math.max(0, 48 - (maxVariableLength + collumnFormat.divider.length));
  const truncatedValue = valueString.slice(0, remainingSpace);

  const spaces = ' '.repeat(Math.max(0, collumnFormat.variableColLength - maxVariableLength));

  return `${truncatedVariable}${spaces}${collumnFormat.divider}${truncatedValue}`;
};

export const columnLeftRight = ({ left, right }) => {
  const maxLength = 48;
  let result = left + ' ' + right;

  if (result.length > maxLength) {
    result = result.slice(0, maxLength);
  } else if (result.length < maxLength) {
    const spacesToAdd = maxLength - result.length;
    result = left + ' '.repeat(spacesToAdd) + right;
  }

  return result;
}

export const columnRight = ({ note, maxLength = 28 }) => {
  const words = note.split(' ');
  let result = [];
  let currentLine = '';

  words.forEach(word => {
    if ((currentLine + word).length <= maxLength) {
      currentLine += (currentLine.length > 0 ? ' ' : '') + word;
    } else {
      result.push(currentLine);
      currentLine = word;
    }
  });

  if (currentLine.length > 0) {
    result.push(currentLine);
  }

  return result;
}
export const getAddOnNameWithPriceRightLimit = ({ addOn, leftSpace = 7, price }) => {
  const result = [];
  const priceString = ` ${formattedDoubleToRupiahString(price)}    `;

  if (typeof addOn !== 'string') {
    return result;
  }

  let remainingAddOn = addOn;

  while (remainingAddOn.length > 0) {
    let addOnPart;
    let availableSpace = 48 - leftSpace - priceString.length;

    if (remainingAddOn.length <= availableSpace) {
      addOnPart = remainingAddOn.trim();
      remainingAddOn = "";
    } else {
      // Find the index of the last space within the available space
      const lastSpaceIndex = remainingAddOn.lastIndexOf(' ', availableSpace);

      // If no space is found, use the entire available space
      const sliceIndex = lastSpaceIndex !== -1 ? lastSpaceIndex : availableSpace;

      addOnPart = remainingAddOn.slice(0, sliceIndex).trim();

      // Check if the last character of addOnPart is not a space
      if (addOnPart.charAt(addOnPart.length - 1) !== ' ' && remainingAddOn.charAt(sliceIndex) !== ' ') {
        // Include the next word along with current addOnPart
        const nextSpaceIndex = remainingAddOn.indexOf(' ', sliceIndex);
        const nextWord = nextSpaceIndex !== -1 ? remainingAddOn.slice(sliceIndex, nextSpaceIndex).trim() : remainingAddOn.slice(sliceIndex).trim();
        addOnPart += ` ${nextWord}`;
        remainingAddOn = remainingAddOn.slice(sliceIndex + nextWord.length).trim();
      } else {
        remainingAddOn = remainingAddOn.slice(sliceIndex).trim();
      }
    }

    // Calculate the remaining space considering the actual length of the price
    const remainingSpaceCount = Math.max(0, 48 - (leftSpace + addOnPart.length + priceString.length));

    let line;
    if (result.length === 0) {
      line = `${' '.repeat(leftSpace)}${addOnPart}${' '.repeat(remainingSpaceCount)}${priceString}`;
    } else {
      line = `${' '.repeat(leftSpace)}${addOnPart}${' '.repeat(remainingSpaceCount)}`;
    }
    result.push(line);
  }

  return result;
};
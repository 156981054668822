import React, { useContext } from 'react';
import { RECORDS_PER_PAGE } from '../../../lib';
import { DashboardContext } from '../../../context/DashboardProvider';

export default function ListOrder() {
  const { orders, setSelectedOrder } = useContext(DashboardContext);
  const [tablePage, setTablePage] = React.useState(1);

  const filteredOrders = React.useMemo(() => {
    if (!orders) return [];

    return orders.slice((tablePage - 1) * RECORDS_PER_PAGE, tablePage * RECORDS_PER_PAGE);
  }, [orders, tablePage]);

  return (
    <div className="w-full min-w-full overflox-x-auto">
      <table className="table sm:table-md table-xs min-w-full">
        {/* head */}
        <thead>
          <tr className="font-semibold md:text-base sm:text-sm text-xs">
            <th>No</th>
            <th>Name</th>
            <th className="sm:table-cell hidden">No Meja</th>
            <th>Total</th>
            <th className="sm:table-cell hidden">Status</th>
            <th className="sm:table-cell hidden">Created At</th>
          </tr>
        </thead>
        <tbody>
          {filteredOrders.map((order) => (
            <tr
              key={order.id}
              className="hover cursor-pointer"
              onClick={() => {
                setSelectedOrder(order);
              }}>
              <td className="">{order.id}</td>
              <td className="">{order.customerName}</td>
              <td className="sm:table-cell hidden">
                {order.type == 'DINE_IN'
                  ? order.tableNumber
                  : order.type == 'TAKE_AWAY'
                    ? 'Take Away'
                    : 'Online'}
              </td>
              <td className="">Rp.{order.totalPrice.toLocaleString('de-DE')}</td>
              <td className="sm:table-cell hidden">
                {order && order.status && order.status.slice(0, 1) + order.status.slice(1).toLowerCase()}
              </td>
              <td className="sm:table-cell hidden">
                {new Date(order.createdAt).toLocaleString('id')}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="join py-2">
        {orders && orders.length > 0 && (
          <>
            {Array.from({ length: Math.ceil(orders.length / RECORDS_PER_PAGE) }, (_, index) => (
              <button
                key={`table-page-${index}`}
                className={`join-item btn ${tablePage == index + 1 ? 'btn-active' : ''}`}
                onClick={() => setTablePage(index + 1)}>
                {index + 1}
              </button>
            ))}
          </>
        )}
      </div>
    </div>
  );
}

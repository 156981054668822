import React, { createContext, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { jwtDecode } from 'jwt-decode';
import { AuthenticatedFetch, GetCookieToken, SetCookieToken } from '../lib';

export const AuthContext = createContext({});

export default function AuthProvider({ children }) {
  const [userId, setUserId] = React.useState([]);
  const [jwtScopes, setJwtScopes] = React.useState([]);
  const [isLoggedIn, setIsLoggedIn] = React.useState(false);

  const scopes = useMemo(() => {
    const { scopes } = getDecodedJWT();
    if (scopes) {
      return scopes;
    }
  }, [userId]);

  useEffect(() => {
    const { isAlive } = getDecodedJWT();
    if (isAlive) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, [userId]);

  useEffect(() => {
    const token = GetCookieToken();

    if (!token) {
      return;
    }

    const decoded = jwtDecode(token);
    if (decoded.exp * 1000 > new Date().getTime()) {
      setIsLoggedIn(false);
      setJwtScopes([]);
      setUserId(null);
    } else {
      setJwtScopes(decoded?.scope);
      setIsLoggedIn(true);
      setUserId(decoded?.id);
    }
  }, []);

  const refetchToken = async () => {
    const res = await AuthenticatedFetch('users/me', {
      method: 'GET'
    });

    const user = await res.json();
    SetCookieToken(user.token);
    location.reload();
  };

  function decideIfRoleHasAccess(scope) {
    return scopes.includes(scope);
  }

  function getDecodedJWT() {
    const token = GetCookieToken();
    if (!token) {
      return { isAlive: false, scopes: [] };
    }

    const decoded = jwtDecode(token);

    if (decoded.exp * 1000 > new Date().getTime()) {
      return { isAlive: true, scopes: decoded?.scope };
    } else {
      return { isAlive: false, scopes: [] };
    }
  }

  return (
    <AuthContext.Provider
      value={{
        jwtScopes,
        userId,
        setJwtScopes,
        setUserId,
        isLoggedIn,
        decideIfRoleHasAccess,
        refetchToken
      }}>
      {children}
    </AuthContext.Provider>
  );
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

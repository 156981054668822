import React, { useContext } from 'react';
import { FaRegEdit } from 'react-icons/fa';
import { FaRegTrashCan } from 'react-icons/fa6';
import { CashierContext } from '../../../context/CashierProvider';
import { Link } from 'react-router-dom';
import { RECORDS_PER_PAGE, scopesEnum } from '../../../lib';
import { AuthContext } from '../../../context/AuthProvider';
import { triggerToast } from '../../../utils';

export default function PlacedOrder() {
  const { orders, setSelectedOrder, setIsOrderToBeDeleted } = useContext(CashierContext);
  const [tablePage, setTablePage] = React.useState(1);
  const { decideIfRoleHasAccess } = React.useContext(AuthContext);

  const filteredOrders = React.useMemo(() => {
    if (!orders) return [];

    return orders.slice((tablePage - 1) * RECORDS_PER_PAGE, tablePage * RECORDS_PER_PAGE);
  }, [orders, tablePage]);

  return (
    <div style={{maxHeight:'50vh'}} className="overflow-y-auto w-full min-w-full overflox-x-auto">
      <div className="join py-2">
        {orders.length > 0 && (
          <>
            {Array.from({ length: Math.ceil(orders.length / RECORDS_PER_PAGE) }, (_, index) => (
              <button
                key={`table-page-${index}`}
                className={`join-item btn ${tablePage == index + 1 ? 'btn-active' : ''}`}
                onClick={() => setTablePage(index + 1)}>
                {index + 1}
              </button>
            ))}
          </>
        )}
      </div>
      <table className="table sm:table-md table-xs min-w-full !text-[1rem]">
        {/* head */}
        <thead>
          <tr className="font-semibold md:text-base text-[1rem]">
            <th>No</th>
            <th>Name</th>
            <th className="sm:table-cell hidden">No Meja</th>
            <th>Total</th>
            <th className="sm:table-cell hidden">Status</th>
            <th className="sm:table-cell hidden">Created At</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {filteredOrders.map((order) => (
            <tr
              key={order.id}
              className="hover cursor-pointer text-[1rem]"
              onClick={() => {
                setSelectedOrder(order);
              }}>
              <td className="text-[1rem]">{order.id}</td>
              <td className="text-[1rem]">{order.customerName}</td>
              <td className="sm:table-cell hidden text-[1rem]">
                {order.type == 'DINE_IN'
                  ? order.tableNumber
                  : order.type == 'TAKE_AWAY'
                    ? 'Take Away'
                    : 'Online'}
              </td>
              <td className="text-[1rem]">Rp.{order.totalPrice.toLocaleString('de-DE')}</td>
              <td className="sm:table-cell hidden text-[1rem]">
                {order.status.slice(0, 1) + order.status.slice(1).toLowerCase()}
              </td>
              <td className="sm:table-cell hidden text-[1rem]">
                {new Date(order.createdAt).toLocaleString('id')}
              </td>
              <td className="flex flex-row gap-2 text-[1rem]">
                {(order.status == "UNPAID" && !decideIfRoleHasAccess(scopesEnum.UPDATE_UNPLACED_ORDER)) ||
                  (order.status == "PAID" && !decideIfRoleHasAccess(scopesEnum.UPDATE_PAID_ORDER)) ||
                  <Link to={`/cashier/${order.id}`}>
                    <FaRegEdit className="cursor-pointer sm:w-6 sm:h-6 w-4 h-4" />
                  </Link>
                }

                {(order.status == "UNPAID" && !decideIfRoleHasAccess(scopesEnum.DELETE_UNPLACED_ORDER)) ||
                  (order.status == "PAID" && !decideIfRoleHasAccess(scopesEnum.DELETE_PAID_ORDER)) ||
                  <FaRegTrashCan
                    className="cursor-pointer sm:w-6 sm:h-6 w-4 h-4"
                    onClick={async () => {
                      if (!decideIfRoleHasAccess(scopesEnum.CANCEL_ORDER)) {
                        triggerToast('error', 'You do not have access to cancel order');
                        return;
                      }

                      setSelectedOrder(order);
                      setIsOrderToBeDeleted(true);
                      document.querySelector('#confirmation-modal').showModal();
                    }}
                  />
                }
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="join py-2">
        {orders.length > 0 && (
          <>
            {Array.from({ length: Math.ceil(orders.length / RECORDS_PER_PAGE) }, (_, index) => (
              <button
                key={`table-page-${index}`}
                className={`join-item btn ${tablePage == index + 1 ? 'btn-active' : ''}`}
                onClick={() => setTablePage(index + 1)}>
                {index + 1}
              </button>
            ))}
          </>
        )}
      </div>
    </div>
  );
}

import React, { useContext } from 'react';
import { MdClose } from 'react-icons/md';
import { CustomerContext } from '../../../context/CustomerProvider';
import { AuthenticatedFetch } from '../../../lib';
import { triggerToast } from '../../../utils';
import { CircularProgress } from '@mui/material';

export default function ConfirmationModal() {
  const {
    tableModal,
    waitingListModal,
    setTables,
    setWaitingLists,
    deleteWaitingListModal,
    waitingListBoxPicked,
    setWaitingListBoxPicked,
    setDeleteWaitingListModal,
    setWaitingListModal
  } = useContext(CustomerContext);
  const [isLoading, setIsLoading] = React.useState(false);

  async function handleAddOrderToTable() {
    const body = {
      state: 'UNPLACED',
      type: 'DINE_IN',
      tableNumber: tableModal.tableNumber,
      printQR: true
    };

    const res = await AuthenticatedFetch(`order`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    });

    if (res.status < 400) {
      const dt = await res.json();

      setTables((prev) => {
        return prev.map((table) => {
          if (table.tableNumber !== tableModal.tableNumber) return table;

          return {
            ...table,
            Order: [...table.Order, dt]
          };
        });
      });

      triggerToast('success', `Order added successfully!`);
      document.querySelector('#modal-customer').close();
    } else {
      const errMessage = await res.text();

      triggerToast('error', errMessage);
    }
  }

  async function handleAddWaitingList() {
    const waitingList = await AuthenticatedFetch('waiting-list', {
      method: 'POST'
    });

    if (waitingList.status < 400) {
      const waitingListData = await waitingList.json();

      triggerToast('success', 'Waiting list added successfully!');

      setWaitingLists((prev) => {
        return [...prev, waitingListData];
      });
      document.querySelector('#modal-customer').close();
    } else {
      const errMessage = await waitingList.text();

      triggerToast('error', errMessage);
    }
  }

  async function handleDeleteWaitingList() {
    const waitingList = await AuthenticatedFetch(
      `waiting-list/${deleteWaitingListModal.id}?date=${deleteWaitingListModal.date}`,
      {
        method: 'DELETE'
      }
    );

    if (waitingList.status < 400) {
      triggerToast('success', 'Waiting list deleted successfully!');

      setWaitingLists((prev) => {
        return prev.filter((waitingList) => waitingList.id !== deleteWaitingListModal.id);
      });
      document.querySelector('#modal-customer').close();
    } else {
      const errMessage = await waitingList.text();

      triggerToast('error', errMessage);
    }
  }

  async function handleAddWaitingListToTable() {
    const order = await AuthenticatedFetch(`order/${waitingListBoxPicked.orderId}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        tableNumber: tableModal.tableNumber
      })
    });

    if (order.status < 400) {
      const orderData = await order.json();
      setTables((prev) => {
        return prev.map((table) => {
          if (table.tableNumber !== tableModal.tableNumber) return table;
          return {
            ...table,
            Order: [...table.Order, orderData]
          };
        });
      });

      const waitingListReq = await AuthenticatedFetch(
        `waiting-list/${waitingListBoxPicked.id}?date=${waitingListBoxPicked.createdAt}`,
        {
          method: 'DELETE'
        }
      );

      const waitingListRes = await waitingListReq.json();
      setWaitingLists((prev) => {
        return prev.filter((waitingList) => waitingList.id !== waitingListRes.id);
      });

      triggerToast(
        'success',
        `Merged waiting list to table ${tableModal.tableNumber} successfully!`
      );
      document.querySelector('#modal-customer').close();
    } else {
      const errMessage = await order.text();

      triggerToast('error', errMessage);
    }
  }

  return (
    <dialog id="modal-customer" className="modal">
      <div className="modal-box relative">
        <MdClose
          className="float-right cursor-pointer sm:w-6 sm:h-6 w-4 h-4 absolute top-6 right-7"
          onClick={() => {
            document.querySelector('#modal-customer').close();
          }}
        />
        <h3 className="font-bold text-lg text-center">
          {tableModal && tableModal.title}
          {waitingListModal && waitingListModal.title}
          {deleteWaitingListModal && deleteWaitingListModal.title}
        </h3>
        <p className="font-semibold text-base text-center flex flex-col items-center gap-2">
          <span>{tableModal && tableModal.body}</span>
          <span>{tableModal && tableModal.subBody !== undefined && tableModal.subBody}</span>
        </p>
        <div className="modal-action">
          <button
            className="button-with-loading btn w-full"
            type="button"
            disabled={isLoading}
            onClick={async () => {
              try {
                setIsLoading(true);

                if (waitingListModal) {
                  await handleAddWaitingList();
                }

                if (deleteWaitingListModal) {
                  await handleDeleteWaitingList();
                }

                if (tableModal && !waitingListBoxPicked) {
                  await handleAddOrderToTable();
                }

                if (tableModal && waitingListBoxPicked) {
                  await handleAddWaitingListToTable();
                }

                setWaitingListBoxPicked(null);
                setDeleteWaitingListModal(null);
                setWaitingListModal(null);
              } finally {
                setIsLoading(false);
              }
            }}>
            {isLoading && <CircularProgress />}
            {deleteWaitingListModal ? 'Delete' : 'Submit'}
          </button>
        </div>
      </div>
      <form method="dialog" className="modal-backdrop">
        <button></button>
      </form>
    </dialog>
  );
}
